import "./styles.scss"

import React from "react"

import t from "locale"

import Button from "components/Button"

const Clients = ({ data, lang }) => {
  return (
    <section className="home-clients">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4 offset-md-1">
            <h2>{data?.clientsHeader}</h2>
          </div>
          <div className="col-md-5 offset-md-1">
            <div
              dangerouslySetInnerHTML={{ __html: data?.clientsDescription }}
            />
          </div>
        </div>
        <div className="row justify-content-center">
          {data?.clientsContent?.map((item, index) => (
            <div className="col-md-5" key={index}>
              <div className="home-clients__tile">
                <div dangerouslySetInnerHTML={{ __html: item.content }} />
                <Button to={t("/kontakt/", lang)}>
                  {t("Skontaktuj się z nami", lang)}
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Clients
