import "./styles.scss"

import React from "react"

const Pillars = ({ data }) => {
  return (
    <section className="home-pillars">
      <div className="container-fluid">
        <h2>{data?.pillarsHeader}</h2>
        <div className="row">
          {data?.pillarsContent?.map((item, index) => (
            <div className="col-xl-3 col-md-6" key={index}>
              <div className="home-pillars__item">
                <div>
                  <img src={item.icon.localFile.publicURL} alt={item.header} />
                  <h4>{item.header}</h4>
                </div>
                <p>{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Pillars
