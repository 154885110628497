import "./styles.scss"

import React, { useState, useEffect } from "react"
import Slider from "react-slick"

import t from "locale"

const References = ({ data, lang }) => {
  const isBrowser = typeof window !== "undefined"
  const [openDescription, setOpenDescription] = useState(false)
  const [width, setWidth] = useState(isBrowser && window.innerWidth)

  useEffect(() => {
    if (!isBrowser) return
    window.addEventListener("resize", () => setWidth(window.innerWidth))
    return () => {
      window.removeEventListener("resize", () => setWidth(window.innerWidth))
    }
  }, [isBrowser])

  const homeSlider = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 750,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: false,
    pauseOnHover: true,
    adaptiveHeight: width <= 767,
  }

  return (
    <section className="home-references">
      <div className="container-fluid">
        <div className="home-references__description">
          <h2>{t("Opinie Klientów", lang)}</h2>
          <p>
            {t(
              "Zobacz, jak wyglądała współpraca z naszymi Klientami przy kilku wybranych projektach.",
              lang
            )}
          </p>
        </div>
        <Slider {...homeSlider}>
          {data?.allWpReference?.nodes.map((item, index) => (
            <div key={index}>
              <div className="home-references__item">
                <h3>{item.title}</h3>
                <div className="row">
                  <div className="col-md-3">
                    <h4>{item.acfReference.referenceAuthor}</h4>
                    <p>{item.acfReference.referenceAuthorPosition}</p>
                    <br />
                    <h5>{t("Zrealizowane usługi", lang)}:</h5>
                    <ul>
                      {item.acfReference.referenceServices.map(
                        (item, index) => (
                          <li key={index}>{item.title}</li>
                        )
                      )}
                    </ul>
                  </div>
                  <div className="col-md-8">
                    <div
                      className={`home-references__item-content${
                        openDescription
                          ? " home-references__item-content--more"
                          : ""
                      }`}
                    >
                      {item.acfReference.referenceOpinion && (
                        <h4>{t("Opinia Klienta", lang)}</h4>
                      )}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.acfReference.referenceOpinion,
                        }}
                      />
                      {item.acfReference.referenceDescription && (
                        <h4>{t("Opis współpracy", lang)}</h4>
                      )}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.acfReference.referenceDescription,
                        }}
                      />
                    </div>
                  </div>
                </div>
                <button
                  className={`home-references__item-cta${
                    openDescription ? " home-references__item-cta--open" : ""
                  }`}
                  onClick={() => setOpenDescription(!openDescription)}
                >
                  {openDescription ? t("Zwiń", lang) : t("Rozwiń", lang)}
                </button>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <img
        className="home-references__image"
        src={require("assets/images/home-references-bg.png").default}
        alt=""
      />
    </section>
  )
}

export default References
