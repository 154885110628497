import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import { Hero, Offer, Clients, Pillars, References } from "page_components/home"

const Home = ({ pageContext, data }) => {
  const dataAcf = pageContext?.data?.acfHome
  const lang = pageContext.data.language.slug

  console.log(pageContext?.data?.seo?.title)

  return (
    <Layout
      seo={{
        title: pageContext?.data?.seo?.title
          ? pageContext?.data?.seo?.title
          : pageContext?.title,
        description: pageContext?.data?.seo?.metaDesc,
      }}
      lang={lang}
      translations={pageContext.data.translations}
    >
      <Hero data={dataAcf} />
      <Offer data={dataAcf} />
      <Clients data={dataAcf} lang={lang} />
      <Pillars data={dataAcf} />
      <References data={data} lang={lang} />
    </Layout>
  )
}

export default Home

export const query = graphql`
  query ($code: WpLanguageCodeEnum!) {
    allWpReference(
      sort: { order: ASC, fields: databaseId }
      filter: { language: { code: { eq: $code } } }
    ) {
      nodes {
        title
        acfReference {
          referenceAuthor
          referenceAuthorPosition
          referenceServices {
            title
          }
          referenceOpinion
          referenceDescription
        }
      }
    }
  }
`
