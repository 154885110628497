import "./styles.scss"

import React from "react"

const Offer = ({ data }) => {
  return (
    <section className="home-offer">
      <div className="container-fluid">
        <h2>{data?.offerHeader}</h2>
        <div className="row">
          {data?.offerContent?.map((item, index) => (
            <div className="col-md-3" key={index}>
              <div className="home-offer__item">
                <div className="home-offer__item-icon">
                  <img
                    src={item.icon.localFile.publicURL}
                    alt={item.description}
                  />
                </div>
                <p>{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Offer
