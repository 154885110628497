import "./styles.scss"

import React from "react"

const Hero = () => {
  return (
    <section className="home-hero">
      <div className="home-hero__wrapper">
        <video autoPlay={true} muted={true} loop={true} playsInline={true}>
          <source
            src={require("assets/videos/home-hero.mp4").default}
            type="video/mp4"
          />
        </video>
      </div>
    </section>
  )
}

export default Hero
